label,
.btn,
li a {
  text-transform: uppercase !important;
}

li label {
  text-transform: initial !important;
}

*:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 111, 183, 0.11) !important;
}

body {
  font-weight: 600;
  font-size: 14px;
  font: 400 14px/24px Roboto, Helvetica Neue, sans-serif;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 10% !important;
}

h2 {
  color: #454444;
  /*text-transform: uppercase;*/
  font-size: 1.2rem;
  padding-bottom: 0 !important;
  margin: 10px 0 0 0 !important;
}

.main {
  /* background-color: #ffffff; */
  background-color: transparent;
  /*padding-left: 15px;
  padding-right: 15px;*/
  padding-bottom: 20px;
}

.text-none-transform {
  text-transform: none !important;
}

.form-error {
  padding-left: 10px;
}

.form-error span {
  opacity: 1 !important;
  color: red;
  font-weight: 300;
  text-transform: capitalize;
}

label {
  display: block;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0.1rem;
}

table td .btn-sm {
  margin-left: 10px;
  padding: 0rem 0.5rem;
  font-size: 11px !important;
  margin-top: -15px;
}

.table {
  margin-bottom: inherit;
  color: #000000;
}

.table-sm td {
  padding: 0.9rem 0.5rem 0.5rem 0.5rem;
}

.table-sm th,
table td {
  display: table-cell;
  vertical-align: middle;
}

.truncate_id_in_table {
  display: inline-block;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate_des_in_table {
  display: inline-block;
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate_default_in_table {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-fox {
  width: 20px;
  height: 20px;
}

.hr {
  border-top: 1px solid #ccc;
}

caption {
  padding-bottom: inherit;
}

.link-reset {
  all: revert;
}

/*** Autocomplete ***/
.autocomplete-container {
  position: relative;
}
.autocomplete-suggestions {
  position: absolute;
  z-index: 999;
  width: 100%;
  max-height: 200px !important;
  overflow-y: auto;
}
.autocomplete-input {
  border: 0;
  outline: none;
  box-shadow: none !important;
  height: 31px;
  padding: 0.25rem 0.5rem;
  min-width: 80px;
  flex: 1;
}
.focused-input-wrapper {
  box-shadow: 0 0 0 0.2rem rgba(15, 111, 183, 0.11);
  border-color: #80bdff;
}

/* sticky-alert */
.sticky-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.fade-in {
  animation: fadein 100ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeout 50ms;
}

@keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.btn-restart-redux {
  position: fixed;
  bottom: 10px;
  right: 200px;
  z-index: 999;
}

.react-datepicker-popper {
  z-index: 5 !important;
  width: max-content;
  transform: translate(0, 31px) !important;
}

/* Caso os breakpoints do gridsystem do bootstrap sejam modificados, essas regras
devem ser revistas.

A regra implementada aqui define que o último datepicker de cada linha esteja
posicionado partindo da direita. Isso evita que o datepicker aumente a largura do
formulário gerando scroll-x desnecessário */

@media (min-width: 1200px) {
  div.row div.col-xl-3:nth-child(4n) .react-datepicker-popper {
    right: 0;
    left: auto !important;
  }

  div.row div.col-xl-3:nth-child(4n) .react-datepicker__triangle {
    right: 50px !important;
    left: auto !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  div.row div.col-lg-4:nth-child(3n) .react-datepicker-popper {
    right: 0;
    left: auto !important;
  }

  div.row div.col-lg-4:nth-child(3n) .react-datepicker__triangle {
    right: 50px !important;
    left: auto !important;
  }
}

@media (max-width: 992px) {
  div.row div.col-6:nth-child(even) .react-datepicker-popper {
    right: 0;
    left: auto !important;
  }

  div.row div.col-6:nth-child(even) .react-datepicker__triangle {
    right: 50px !important;
    left: auto !important;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

/* input group */
.input-group-sm > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-sm > .react-datepicker-wrapper {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.input-group-sm > .react-datepicker-wrapper > .react-datepicker__input-container {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.input-group > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

div.invalid-composition {
  font-size: 0.875em;
  color: #dc3545;
}

input.invalid-composition {
  border-color: #dc3545;
}

input.invalid-composition:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%) !important;
}

/* input group */
.nav-tabs .nav-link.active {
  font-weight: 500;
}
.nav-tabs .nav-link:focus {
  outline: none;
  box-shadow: none !important;
}

.input-find-relation-classes {
  margin-top: 1rem !important;
  border-radius: 0;
  border-top-left-radius: 0.25rem !important;
  border-bottom: none;
  border-right: none;

  padding: 0.938em 4em 0.938em 1em;
  font-size: 1em;
  width: 100%;
  min-height: 39px;
}

input[list] {
  width: 100%;
}

@media (max-width: 768px) {
  .input-find-relation-classes {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .mb-not {
    margin-bottom: 0 !important;
  }
  .mt-not {
    margin-top: 0 !important;
  }
}

/**** input with clear button ****/

form.search-form {
  position: relative;
}

/*** List Component ***/
.list-for-autocomplete div.active {
  background-color: #ccc;
}

.list-for-autocomplete label {
  display: inline-block;
}

.list-for-autocomplete input {
  margin-right: 0.4em;
}

/* table orderby */
.sort-icon {
  opacity: 0.5;
  cursor: pointer;
}
.sort-icon-active {
  cursor: pointer;
}

/* table cel wrap */
.text-wrapping {
  word-wrap: break-word;
}


/* fix relation group */
.input-group {
  flex-wrap: unset;
}
